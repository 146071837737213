<!--



 * @ Description: 司机管理>C端客户>C端用户统计 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns">
      <template #headerButtons>
        <div class="table-header-button">
          <template v-for="(btn, i) in pageButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
              {{ btn.text }}
            </ykc-button>
          </template>
        </div>
      </template>
      <ykc-pagination
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
  </scroll-layout>
</template>

<script>
  import { customerUser } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    name: 'customerStatisticsList',
    components: {},
    data() {
      return {
        id: null,
        drawerType: '',
        stepsIndex: 0,
        maxStepIndex: 1,
        isEdit: false,
        showDrawer: false,
        drawerButtons: [
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            text: '导出',
            type: 'plain',
            enabled: () => code('customer:statistics:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: 'C端用户统计列表',
        tableColumns: [
          { label: this.$t('账号'), prop: 'userAccount', minWidth: '150px' },
          { label: '姓名', prop: 'nickName', minWidth: '150px' },
          { label: '手机号', prop: 'userPhone', minWidth: '150px' },
          { label: '余额', prop: 'balance', minWidth: '100px' },
          {
            label: '用户状态',
            prop: 'status',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.status === '1' ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', startStatus]}>
                    {row.status === '1' ? '启用' : '停用'}
                  </span>
                );
              },
            },
          },
          { label: '常用站点', prop: 'commomStationName', minWidth: '250px' },
          { label: '注册时间', prop: 'createdTime', minWidth: '200px' },
          { label: '首次充电时间', prop: 'firstChargeTime', minWidth: '200px' },
          { label: '最近一次充电时间', prop: 'lastChargeTime', minWidth: '200px' },
          { label: '最近一次充电电站', prop: 'lastStationName', minWidth: '250px' },
          { label: '本月充电次数', prop: 'currentMonthCount', minWidth: '100px' },
          { label: '上月充电次数', prop: 'lastMonthCount', minWidth: '100px' },
          { label: '上上月充电次数', prop: 'twoMonthsAgoCount', minWidth: '100px' },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          userType: '1', // '1' C端司机 '2'客户司机 '3'子公司司机
          belongToName: '',
          userAccount: '',
          nickName: '',
          userPhone: '',
          mainOrgName: '',
        },
      };
    },
    computed: {
      /** 搜索表单数据 */
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '账号',
            placeholder: '请输入账号',
          },
          {
            comName: 'YkcInput',
            key: 'nickName',
            label: '姓名',
            placeholder: '请输入姓名',
          },
          {
            comName: 'YkcInput',
            key: 'userPhone',
            label: '手机号',
            placeholder: '请输入手机号',
          },
        ];
      },
    },
    created() {
      this.searchParams.userAccount = this.$route.query.userAccount;
      this.id = this.$route.query.id;
      this.searchTableList();
      this.initData();

      if (this.id) {
        this.stepsIndex = 0;
        this.maxStepIndex = 1;
        this.isEdit = true;
        this.drawerType = 'edit';
        this.showDrawer = true;
      }
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          customerUser.queryUserDataList({
            ...this.pageInfo,
            ...this.searchParams,
            userType: '1',
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'c_user_data',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total,
          null,
          true
        );
      },

      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);

        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .unUsed {
    margin-left: 20px;
    font-size: 0;
    line-height: 30px;
    color: transparent;

    &::before,
    &::after {
      font-size: 16px;
      color: #0000ff;
    }
  }
  .onUse {
    margin-left: 20px;
    font-size: 0;
    line-height: 30px;
    color: transparent;

    &::before,
    &::after {
      font-size: 16px;
      color: #0000ff;
    }
  }

  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }
      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }
      .cell .status-blue::after {
        background: #4d8cfd;
      }
      .cell .status-green::after {
        background: #02c498;
      }
      .cell .status-black::after {
        background: #9b9b9b;
      }
    }
    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }
    .row-item {
      padding: 0px 4px;
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 7px;
      display: inline-block;
    }

    .row-item-txt {
      padding: 0 4px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
    }
    .row-green {
      background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
    }
    .row-blue {
      background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
    }
  }
</style>
